// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-cw-pages-404-js": () => import("./../../../src/CW/pages/404.js" /* webpackChunkName: "component---src-cw-pages-404-js" */),
  "component---src-cw-pages-about-us-js": () => import("./../../../src/CW/pages/about-us.js" /* webpackChunkName: "component---src-cw-pages-about-us-js" */),
  "component---src-cw-pages-blog-js": () => import("./../../../src/CW/pages/blog.js" /* webpackChunkName: "component---src-cw-pages-blog-js" */),
  "component---src-cw-pages-contact-js": () => import("./../../../src/CW/pages/contact.js" /* webpackChunkName: "component---src-cw-pages-contact-js" */),
  "component---src-cw-pages-index-js": () => import("./../../../src/CW/pages/index.js" /* webpackChunkName: "component---src-cw-pages-index-js" */),
  "component---src-cw-pages-ppc-js": () => import("./../../../src/CW/pages/ppc.js" /* webpackChunkName: "component---src-cw-pages-ppc-js" */),
  "component---src-cw-pages-privacy-policy-js": () => import("./../../../src/CW/pages/privacy-policy.js" /* webpackChunkName: "component---src-cw-pages-privacy-policy-js" */),
  "component---src-cw-pages-redirect-js": () => import("./../../../src/CW/pages/redirect.js" /* webpackChunkName: "component---src-cw-pages-redirect-js" */),
  "component---src-cw-pages-terms-of-service-js": () => import("./../../../src/CW/pages/terms-of-service.js" /* webpackChunkName: "component---src-cw-pages-terms-of-service-js" */),
  "component---src-cw-templates-region-js": () => import("./../../../src/CW/templates/Region.js" /* webpackChunkName: "component---src-cw-templates-region-js" */),
  "component---src-cw-templates-theme-js": () => import("./../../../src/CW/templates/Theme.js" /* webpackChunkName: "component---src-cw-templates-theme-js" */),
  "component---src-cw-templates-themed-region-js": () => import("./../../../src/CW/templates/ThemedRegion.js" /* webpackChunkName: "component---src-cw-templates-themed-region-js" */)
}

